import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/Navigation';

const DashboardPage = () => {
  const navigate = useNavigate();

  // Define your cards and corresponding navigation paths
  const cards = [
    {
        title: 'Facility Management',
        image: 'https://biblus.accasoftware.com/en/wp-content/uploads/sites/2/2022/10/Facility-Management-Functions.webp',
        path: '/facility-management'
      },  
      
      {
        title: 'Staff Coordination',
        image: 'https://static.startuptalky.com/2022/04/Team-members-in-Project-Management-StartupTalky.jpg',
        path: '/staff-coordination'
      }, 

      {
        title: 'Compliance Oversight',
        image: 'https://reciprocity.com/wp-content/uploads/2019/01/compliance-automation-768x431.jpg',
        path: '/compliance-oversight'
      },

      {
        title: 'Incident Response',
        image: 'https://www.highwirenetworks.com/wp-content/uploads/2020/04/incident-response-resize-3-1.jpg',
        path: '/incident-response'
      },


      
      
    
  ];

  return (
    <div className="dashboard-page">
      <Navigation username="Admin" />
      <div className="dashboard-cards">
        {cards.map((card, index) => (
          <div
            key={index}
            className="dashboard-card"
            onClick={() => navigate(card.path)}
          >
            <div className="card-image-container">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-title">{card.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
