// IncidentResponsePage.js
import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation";
import "./IncidentResponsePage.css"; // CSS for styling this page
import axios from "axios";
import url from "../../url";

const IncidentResponsePage = () => {
  const [incidents, setIncidents] = useState([
    {
      id: 1,
      title: "test",
      location: "test",
      description: "test",
      date: "12/04/24",
    },
    {
      id: 2,
      title: "test",
      location: "test",
      description: "test",
      date: "12/04/24",
    },
  ]);
  const [newIncident, setNewIncident] = useState({
    title: "",
    location: "",
    description: "",
    date: "",
  });

  const fetchIncidentReportData = async () => {
    try {
      const response = await axios.get(`${url}/incidents`);
      console.log(response);
      setIncidents(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIncidentReportData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewIncident((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${url}/incidents`, {
        ...newIncident,
      });
      console.log(response);
      fetchIncidentReportData();
    } catch (error) {
      console.log(error);
    }
    const newId = incidents.length + 1;
    setIncidents([
      ...incidents,
      { ...newIncident, id: newId, status: "Active" },
    ]);
    setNewIncident({ description: "", severity: "" }); // Reset form
  };

  return (
    <div>
      <NavigationBar />
      <div className="incident-response-container">
        <h1>Incident Response Coordination</h1>
        <form onSubmit={handleSubmit} className="incident-form">
          <input
            type="text"
            name="title"
            placeholder="Incident Title"
            value={newIncident.title}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="description"
            placeholder="Incident Description"
            value={newIncident.description}
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="location"
            placeholder="Incident Location"
            value={newIncident.location}
            onChange={handleInputChange}
            required
          />
          <input
            type="date"
            name="description"
            placeholder="Incident Description"
            value={newIncident.description}
            onChange={handleInputChange}
            required
          />
          <button type="submit" className="add-incident-button">
            Add Incident
          </button>
        </form>
        <ul>
          {incidents.map((incident) => (
            <li key={incident.id}>
              <p>
                {incident.title} - Description : {incident.description} -
                Location : {incident.location} - Date: {incident.date}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default IncidentResponsePage;
