// EPrescriptionsPage.js
import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation";
import "./EPrescriptionsPage.css";
import axios from "axios";
import url from "../../url";

const EPrescriptionsPage = () => {
  const [patients, setPatients] = useState([]);
  const [prescription, setPrescription] = useState({
    patient: "",
    medication: "",
    dosage: "",
    frequency: "",
    notes: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPrescription((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      const filteredData = response.data.filter(
        (item) => item.role === "patient"
      );
      setPatients(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPatients();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${url}/prescriptions`, {
        ...prescription,
      });
      console.log(response);
      alert("Prescription submitted successfully!");
      setPrescription({
        patient: "",
        medication: "",
        dosage: "",
        frequency: "",
        notes: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="eprescriptions-container">
        <h1>E-Prescriptions</h1>
        <form onSubmit={handleSubmit} className="prescription-form">
          <select
            id="doctor"
            name="patient"
            value={prescription.patient}
            onChange={handleChange}
            required
          >
            <option value="">Select a Patient</option>
            {patients?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="medication"
            placeholder="Medication"
            value={prescription.medication}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="dosage"
            placeholder="Dosage (e.g., 500 mg)"
            value={prescription.dosage}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="frequency"
            placeholder="Frequency (e.g., once daily)"
            value={prescription.frequency}
            onChange={handleChange}
            required
          />
          <textarea
            name="notes"
            placeholder="Additional Instructions"
            value={prescription.notes}
            onChange={handleChange}
            required
          />
          <button type="submit" className="submit-button">
            Submit Prescription
          </button>
        </form>
      </div>
    </div>
  );
};

export default EPrescriptionsPage;
