// DataOversightPage.js
import React from 'react';
import NavigationBar from '../../components/Navigation';
import './DataOversightPage.css'; // CSS for styling this page

const DataOversightPage = () => {
    // This is a static example. In a real application, you would fetch this data from a backend API.
    const systemAlerts = [
        { id: 1, type: 'Error', message: 'Data breach detected in sector 4G.', timestamp: '2023-04-01 15:24:00' },
        { id: 2, type: 'Warning', message: 'Unusually high data request rate detected.', timestamp: '2023-04-02 11:17:00' },
        { id: 3, type: 'Info', message: 'System backup completed successfully.', timestamp: '2023-04-03 03:45:00' }
    ];

    return (
        <div>
            <NavigationBar />
            <div className="data-oversight-container">
                <h1>Data Oversight Dashboard</h1>
                <div className="alerts">
                    <h2>Recent Alerts</h2>
                    {systemAlerts.map(alert => (
                        <div key={alert.id} className={`alert ${alert.type.toLowerCase()}`}>
                            <p><strong>{alert.type}</strong> - {alert.message}</p>
                            <span>{alert.timestamp}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DataOversightPage;
