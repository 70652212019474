/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import url from "../../url";
import Navigation from "../../components/Navigation";

function SystemConfigurationPage() {
  const [theme, setTheme] = useState("light");
  const [language, setLanguage] = useState("English");
  const [dateFormat, setDateFormat] = useState("MM/DD/YYYY");
  const [smtpServer, setSmtpServer] = useState("");
  const [logLevel, setLogLevel] = useState("info");
  const [monitoringThreshold, setMonitoringThreshold] = useState("");
  const [backupSchedule, setBackupSchedule] = useState("Daily");
  const [auditTrailEnabled, setAuditTrailEnabled] = useState(false);
  const [versionControlEnabled, setVersionControlEnabled] = useState(false);

  useEffect(() => {
    const fetchSystemConfiguration = async () => {
      try {
        const response = await axios.get(`${url}/admin/system-configure`);
        const systemConfiguration = response.data.config;
        console.log(systemConfiguration);
        setTheme(systemConfiguration.theme);
        setLanguage(systemConfiguration.language);
        setDateFormat(systemConfiguration.dateFormat);
        setSmtpServer(systemConfiguration.smtpServer);
        setLogLevel(systemConfiguration.logLevel);
        setMonitoringThreshold(systemConfiguration.monitoringThreshold);
        setBackupSchedule(systemConfiguration.backupSchedule);
        setAuditTrailEnabled(systemConfiguration.auditTrailEnabled);
        setVersionControlEnabled(systemConfiguration.versionControlEnabled);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSystemConfiguration();
  }, []);

  const handleThemeChange = (selectedTheme) => {
    setTheme(selectedTheme);
  };

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
  };

  const handleDateFormatChange = (selectedDateFormat) => {
    setDateFormat(selectedDateFormat);
  };

  const handleSmtpServerChange = (value) => {
    setSmtpServer(value);
  };

  const handleLogLevelChange = (selectedLogLevel) => {
    setLogLevel(selectedLogLevel);
  };

  const handleMonitoringThresholdChange = (value) => {
    setMonitoringThreshold(value);
  };

  const handleBackupScheduleChange = (selectedSchedule) => {
    setBackupSchedule(selectedSchedule);
  };

  const handleAuditTrailToggle = () => {
    setAuditTrailEnabled(!auditTrailEnabled);
  };

  const handleVersionControlToggle = () => {
    setVersionControlEnabled(!versionControlEnabled);
  };
  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${url}/admin/system-configure`, {
        theme: theme,
        language: language,
        dateFormat: dateFormat,
        smtpServer: smtpServer,
        logLevel: logLevel,
        monitoringThreshold: monitoringThreshold,
        backupSchedule: backupSchedule,
        auditTrailEnabled: auditTrailEnabled,
        versionControlEnabled: versionControlEnabled,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="configuration-container">
      <Navigation />
      {/* Theme Configuration */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>System Configuration</h1>
        <div className="configuration-section">
          <h3>Theme Configuration</h3>
          <select
            value={theme}
            onChange={(e) => handleThemeChange(e.target.value)}
          >
            <option value="light">Light</option>
            <option value="dark">Dark</option>
            {/* Add more theme options */}
          </select>
        </div>
        {/* Language Configuration */}
        <div className="configuration-section">
          <h3>Language Configuration</h3>
          <select
            value={language}
            onChange={(e) => handleLanguageChange(e.target.value)}
          >
            <option value="English">English</option>
            <option value="Spanish">Spanish</option>
            {/* Add more language options */}
          </select>
        </div>
        {/* Date Format Configuration */}
        <div className="configuration-section">
          <h3>Date Format Configuration</h3>
          <select
            value={dateFormat}
            onChange={(e) => handleDateFormatChange(e.target.value)}
          >
            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
            <option value="YYYY-MM-DD">YYYY-MM-DD</option>
            {/* Add more date format options */}
          </select>
        </div>
        {/* Save Button */}
        <button onClick={handleSubmit} className="save-button">
          Save Configuration
        </button>
      </div>
    </div>
  );
}

export default SystemConfigurationPage;
