import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation"; // Corrected import path
import axios from "axios";
import url from "../../url";

const CommunityInteractionPage = () => {
  const [commComment, setCommComment] = useState("");
  const [curr, setCurr] = useState("");
  const [posts, setPosts] = useState([
    {
      id: 1,
      author: "Dr. John Doe",
      content: "Regular exercise and a balanced diet are key for good health.",
      date: "2024-03-20",
    },
    {
      id: 2,
      author: "Patient123",
      content: "Struggling with managing my diabetes. Any advice?",
      date: "2024-03-19",
    },
    // Add more posts as needed
  ]);
  const [newPostContent, setNewPostContent] = useState("");

  const fetchPostData = async () => {
    try {
      const response = await axios.get(`${url}/community/patient`);
      console.log(response);
      setPosts(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPostData();
  }, []);

  const handleNewPostChange = (event) => {
    setNewPostContent(event.target.value);
  };

  const handleCommentSubmit = async (e, communicationId) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/community/patient/comment`, {
        comment: commComment,
        author: sessionStorage.getItem("name") || "user",
        article: communicationId,
      });
      console.log(response);
      fetchPostData();
    } catch (error) {
      console.log(error);
    }
  };

  const postNewPost = async () => {
    if (newPostContent.trim()) {
      try {
        const author = sessionStorage.getItem("name") || "user";
        const response = await axios.post(`${url}/community/patient`, {
          author: author,
          content: newPostContent,
          date: new Date().toISOString().split("T")[0],
        });
        console.log(response);
        setNewPostContent("");
        alert("Post Created");
        fetchPostData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <NavigationBar />
      <div style={{ padding: "20px" }}>
        <textarea
          placeholder="Write your post here..."
          style={{ width: "100%", minHeight: "100px", margin: "10px 0" }}
          value={newPostContent}
          onChange={handleNewPostChange}
        />
        <button onClick={postNewPost}>Post</button>
        <div style={{ marginTop: "20px" }}>
          <h2>Health Forum</h2>
          {posts.map((post) => (
            <div
              key={post.id}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                margin: "10px 0",
              }}
            >
              <p>{post.author}</p>
              <p>{post.content}</p>
              <div className="communityInteractionComments">
                <h3>Comments:</h3>
                {post?.comments?.map((comment) => (
                  <div>
                    <h4>USER :{comment.author}</h4>
                    <span>{comment.comment}</span>
                  </div>
                ))}
              </div>
              <div>
                <form noValidate>
                  <div className="form-group">
                    <label>Enter your comment here:</label>
                    <textarea
                      style={{ width: "50%" }}
                      value={curr === post.id ? commComment : null}
                      onChange={(e) => {
                        setCurr(post.id);
                        setCommComment(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button onClick={(e) => handleCommentSubmit(e, post.id)}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityInteractionPage;
