// StaffCoordinationPage.js
import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation";
import "./StaffCoordinationPage.css"; // CSS for styling this page
import axios from "axios";
import url from "../../url";

const StaffCoordinationPage = () => {
  const [staff, setStaff] = useState([
    {
      id: 1,
      name: "Dr. Sarah Connor",
      email: "test@gmail.com",
      address: "Test",
    },
    {
      id: 2,
      name: "Dr. John Smith",
      email: "test@gmail.com",
      address: "Test",
    },
  ]);
  const [newStaff, setNewStaff] = useState({
    name: "",
    address: "",
    email: "",
  });

  const fetchStaffData = async () => {
    try {
      const response = await axios.get(`${url}/staff`);
      console.log(response);
      setStaff(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStaffData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewStaff((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${url}/staff`, {
        ...newStaff,
      });
      console.log(response);
      setNewStaff({ name: "", role: "", accessLevel: "" });
      fetchStaffData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/staff/${id}`);
      console.log(response);
      fetchStaffData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="staff-coordination-container">
        <h1>Staff Coordination</h1>
        <form onSubmit={handleSubmit} className="staff-form">
          <input
            type="text"
            name="name"
            placeholder="Staff Name"
            value={newStaff.name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="address"
            placeholder="Staff Address"
            value={newStaff.address}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Staff Mail ID"
            value={newStaff.email}
            onChange={handleChange}
            required
          />
          <button type="submit" className="add-staff-button">
            Add Staff
          </button>
        </form>
        <ul>
          {staff.map((member) => (
            <li key={member.id}>
              <p>
                {member.name} - Email : {member.email} - Address:{" "}
                {member.address}
              </p>
              <button onClick={() => handleDelete(member.id)}>Remove</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StaffCoordinationPage;
