// pages/AppointmentManagementPage.js

import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation"; // Corrected import path
import "./AppointmentManagementPage.css"; // Ensure you have the corresponding CSS file
import axios from "axios";
import url from "../../url";

const AppointmentManagementPage = () => {
  const [appointments, setAppointments] = useState([]);
  const [patients, setPatients] = useState([]);
  const [newAppointment, setNewAppointment] = useState({
    date: "",
    time: "",
    doctorName: "",
    patient: "",
  });

  const fetchAppointments = async () => {
    try {
      const response = await axios.get(`${url}/appointments`);
      console.log(response);
      setAppointments(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      const filteredData = response.data.filter(
        (item) => item.role === "patient"
      );
      setPatients(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAppointments();
    fetchPatients();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment({ ...newAppointment, [name]: value });
  };

  const handleSchedule = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${url}/appointments`, {
        patient: newAppointment.patient,
        doctorName: newAppointment.doctorName,
        date: newAppointment.date,
        time: newAppointment.time,
      });
      console.log(response);
      setNewAppointment({ date: "", time: "", doctorName: "", patient: "" });
      fetchAppointments();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="appointment-management-container">
        <h1>Appointment Management</h1>
        <form onSubmit={handleSchedule} className="appointment-form">
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              id="date"
              name="date"
              type="date"
              value={newAppointment.date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="time">Time</label>
            <input
              id="time"
              name="time"
              type="time"
              value={newAppointment.time}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="patient">Patient</label>
            <select
              id="doctor"
              name="patient"
              value={newAppointment.patient}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a Patient</option>
              {patients?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="doctorName">Doctor</label>
            <select
              id="doctor"
              name="doctorName"
              value={newAppointment.doctorName}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a Doctor</option>
              <option value="Dr. Smith">Dr. Smith</option>
              <option value="Dr. Johnson">Dr. Johnson</option>
              <option value="Dr. Brown">Dr. Brown</option>
            </select>
          </div>
          <button type="submit" className="btn schedule-button">
            Schedule
          </button>
        </form>

        <div className="appointments-list">
          {appointments.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Doctor</th>
                </tr>
              </thead>
              <tbody>
                {appointments.map((appt, index) => (
                  <tr key={index}>
                    <td>{appt.date}</td>
                    <td>{appt.time}</td>
                    <td>{appt.doctorName}</td>
                    <td>{appt.patient}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No appointments scheduled.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppointmentManagementPage;
