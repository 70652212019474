// ComplianceOversightPage.js
import React, { useState, useEffect } from "react";
import NavigationBar from "../../components/Navigation";
import "./ComplianceOversightPage.css"; // CSS for styling this page
import axios from "axios";
import url from "../../url";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Button, FormControl } from "@mui/base";

const ComplianceOversightPage = () => {
  const [complianceIssues, setComplianceIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editForm, setShowEditForm] = React.useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [selectedRow, setSelectdRow] = useState({});
  const [formData, setFormData] = useState({
    complianceStatus: "",
    priority: "",
    taskName: "",
  });

  const fetchStaffData = async () => {
    try {
      const response = await axios.get(`${url}/compliance`);
      console.log(response);
      setComplianceIssues(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStaffData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    setShowEditForm(false);
    setFormData({
      complianceStatus: "",
      priority: "",
      taskName: "",
    });
    setIsEdit(-1);
  };

  const handleSubmit = async (event) => {
    if (isEdit > 0) {
      try {
        const response = await axios.put(`${url}/compliance/${isEdit}`, {
          ...formData,
        });
        console.log(response);
        fetchStaffData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.post(`${url}/compliance`, {
          ...formData,
        });
        console.log(response);
        fetchStaffData();
      } catch (error) {
        console.log(error);
      }
    }
    event.preventDefault();
    console.log(formData);
    handleClose();
    setIsEdit(-1);
  };

  const handleEdit = (item) => {
    setIsEdit(item.id);
    setSelectdRow(item);
    setFormData(item);
    setShowEditForm(true);
  };
  const handleAdd = () => {
    setShowEditForm(true);
    setFormData({
      complianceStatus: "",
      priority: "",
      taskName: "",
    });
  };

  return (
    <div>
      <NavigationBar />
      <div className="compliance-oversight-container">
        <h1>Compliance Oversight Dashboard</h1>
        {loading ? (
          <p>Loading compliance issues...</p>
        ) : (
          <ul>
            {complianceIssues.map((issue) => (
              <li key={issue.id}>
                <p>
                  <strong>{issue.priority} Priority:</strong> {issue.taskName}
                </p>
                <p>Status: {issue.complianceStatus}</p>
                <Button
                  variant="text"
                  onClick={() => {
                    handleEdit(issue);
                  }}
                >
                  Edit
                </Button>
              </li>
            ))}
          </ul>
        )}

        <button onClick={handleAdd}>+ Add Compliance</button>
      </div>
      <Dialog open={editForm} onClose={handleClose}>
        <DialogTitle>Form</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="taskName"
                  label="Compliance Description"
                  required
                  value={formData.taskName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    required
                    name="complianceStatus"
                    value={formData.complianceStatus}
                    onChange={handleChange}
                  >
                    <MenuItem value="Compliant">Compliant</MenuItem>
                    <MenuItem value="In Progress">In Progress</MenuItem>
                    <MenuItem value="Non-Compliant">Non-Compliant</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Priority</InputLabel>
                  <Select
                    required
                    name="priority"
                    placeholder="Select Status"
                    value={formData.priority}
                    onChange={handleChange}
                  >
                    <MenuItem value="high">High</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="low">Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ComplianceOversightPage;
