// pages/HealthcareProviderManagementPage.js

import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navigation";
import "./HealthcareProviderManagementPage.css"; // Make sure to create a CSS file for this component
import axios from "axios";
import url from "../../url";

const HealthcareProviderManagementPage = () => {
  const [providers, setProviders] = useState([
    {
      id: 1,
      name: "Dr. John Doe",
      email: "john.doe@example.com",
      specialization: "Cardiology",
    },
    {
      id: 2,
      name: "Dr. Jane Smith",
      email: "jane.smith@example.com",
      specialization: "Pediatrics",
    },
  ]);

  const fetchHealthCareProvider = async () => {
    try {
      const response = await axios.get(`${url}/health-care-providers`);
      console.log(response);
      setProviders(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchHealthCareProvider();
  }, []);
  const toggleProviderStatus = async (providerId, status, name, spec) => {
    try {
      const response = await axios.put(
        `${url}/health-care-providers/${providerId}`,
        {
          name: name,
          specialization: spec,
          status: status === "Deactivate" ? "inactive" : "active",
        }
      );
      console.log(response);
      fetchHealthCareProvider();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navigation />
      <div className="provider-management-container">
        <h2>Healthcare Provider Management</h2>
        <table className="provider-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Specialization</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {providers.map((provider) => (
              <tr key={provider.id}>
                <td>{provider.name}</td>
                <td>{provider?.status}</td>
                <td>{provider.specialization}</td>
                <td>
                  {provider.status === "active" ? (
                    <button
                      onClick={() =>
                        toggleProviderStatus(
                          provider.id,
                          provider.status === "active"
                            ? "Deactivate"
                            : "Activate",
                          provider.name,
                          provider.specialization
                        )
                      }
                      className="admin-button deactivate"
                    >
                      Deactivate User
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        toggleProviderStatus(
                          provider.id,
                          provider.status === "active"
                            ? "Deactivate"
                            : "Activate",
                          provider.name,
                          provider.specialization
                        )
                      }
                      className="admin-button activate"
                    >
                      Activate User
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HealthcareProviderManagementPage;
