// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutUsPage from "./pages/AboutUsPage";
import ServicesPage from "./pages/ServicesPage";
import ContactUsPage from "./pages/ContactUsPage";
import LoginPage from "./pages/LoginPage";
import CreateUserPage from "./pages/CreateUserPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import DashboardPage from "./pages/DashboardPage";
import UserManagementPage from "./pages/Administrator/UserManagementPage";
import HealthcareProviderManagementPage from "./pages/Administrator/HealthcareProviderManagementPage";
import PatientHealthRecordsPage from "./pages/PatientHealthRecordsPage";
import SymptomCheckerPage from "./pages/Patients Healthcare Consumers/SymptomCheckerPage";
import PatientHealthcareDashboard from "./pages/Patients Healthcare Consumers/PatientsHealthcareDashboard";
import MedicationRemindersPage from "./pages/Patients Healthcare Consumers/MedicationRemindersPage";
import PersonalHealthRecordsPage from "./pages/Patients Healthcare Consumers/PersonalHealthRecordsPage";
import AppointmentManagementPage from "./pages/Healthcare Providers/AppointmentManagementPage";
// import AppointmentManagementPage from "./pages/Patients Healthcare Consumers/AppointmentManagementPage";
import PrescriptionManagementPage from "./pages/Patients Healthcare Consumers/PrescriptionManagementPage";
import CommunityInteractionPage from "./pages/Patients Healthcare Consumers/CommunityInteractionPage";
import HealthcareProvidersDashboard from "./pages/Healthcare Providers/HealthcareProvidersDashboard";
import EPrescriptionsPage from "./pages/Healthcare Providers/EPrescriptionsPage";
import PatientHealthRecordsAccessPage from "./pages/Healthcare Providers/PatientHealthRecordsAccessPage";
import ProfessionalCollaborationPage from "./pages/Healthcare Providers/ProfessionalCollaborationPage";
import AnalyticsDashboardPage from "./pages/Healthcare Providers/AnalyticsDashboardPage";
import AdministratorDashboard from "./pages/Administrator/AdministratorDashboard";
import SystemConfigurationPage from "./pages/Administrator/SystemConfigurationPage";
import DataOversightPage from "./pages/Administrator/DataOversightPage";
import ReportGenerationPage from "./pages/Administrator/ReportGenerationPage";
import HealthAdministratorDashboard from "./pages/Health Administrator/HealthAdministratorDashboard";
import FacilityManagementPage from "./pages/Health Administrator/FacilityManagementPage";
import StaffCoordinationPage from "./pages/Health Administrator/StaffCoordinationPage";
import IncidentResponsePage from "./pages/Health Administrator/IncidentResponsePage";
import ComplianceOversightPage from "./pages/Health Administrator/ComplianceOversightPage";
import PharmacistDashboard from "./pages/Pharmacist/PharmacistDashboard";
import MedicationDispensationPage from "./pages/Pharmacist/MedicationDispensationPage";
import MedicationHistoryPage from "./pages/Pharmacist/MedicationHistoryPage";
import SecureMessagingPage from "./pages/Pharmacist/SecureMessagingPage";
import PrivateRoute from "./PrivateRoute";
import PatientAppointmentManagementPage from "./pages/Patients Healthcare Consumers/PatientAppointmentManagementPage";
import HealthRecordsAccess from "./pages/Healthcare Providers/PatientHealthRecordsAccessPage";
import RegistrationPage from "./pages/RegistrationPage";
import Message from "./pages/Chat";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/create-user" element={<CreateUserPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<RegistrationPage />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<HomePage />} />
        <Route
          path="/user-management"
          element={
            <PrivateRoute>
              <UserManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/healthcare-provider-management"
          element={
            <PrivateRoute>
              <HealthcareProviderManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/appointment-management"
          element={
            <PrivateRoute>
              <AppointmentManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/patient-health-records"
          element={
            <PrivateRoute>
              <PatientHealthRecordsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/community-interactions"
          element={<CommunityInteractionPage />}
        />
        <Route
          path="/symptom-checker-page"
          element={
            <PrivateRoute>
              <SymptomCheckerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/patients-healthcare-dashboard"
          element={
            <PrivateRoute>
              <PatientHealthcareDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/patients-appointment-management"
          element={
            <PrivateRoute>
              <PatientAppointmentManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/medication-reminders-page"
          element={
            <PrivateRoute>
              <MedicationRemindersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/personal-health-records-page"
          element={
            <PrivateRoute>
              <PersonalHealthRecordsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/prescription-management"
          element={
            <PrivateRoute>
              <PrescriptionManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/healthcare-providers-dashboard"
          element={
            <PrivateRoute>
              <HealthcareProvidersDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/e-prescriptions"
          element={
            <PrivateRoute>
              <EPrescriptionsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/health-records-access"
          element={
            <PrivateRoute>
              <HealthRecordsAccess />
            </PrivateRoute>
          }
        />
        <Route
          path="/professional-collaboration"
          element={
            <PrivateRoute>
              <ProfessionalCollaborationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/analytics-dashboard"
          element={
            <PrivateRoute>
              <AnalyticsDashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/administrator-dashboard"
          element={
            <PrivateRoute>
              <AdministratorDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/system-configuration"
          element={
            <PrivateRoute>
              <SystemConfigurationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/data-oversight"
          element={
            <PrivateRoute>
              <DataOversightPage />
            </PrivateRoute>
          }
        />
        {/* <Route path="/report-generation" element={<PrivateRoute><ReportGenerationPage /></PrivateRoute>} /> */}
        <Route
          path="/health-administrator-dashboard"
          element={
            <PrivateRoute>
              <HealthAdministratorDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/facility-management"
          element={
            <PrivateRoute>
              <FacilityManagementPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/staff-coordination"
          element={
            <PrivateRoute>
              <StaffCoordinationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/compliance-oversight"
          element={
            <PrivateRoute>
              <ComplianceOversightPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/incident-response"
          element={
            <PrivateRoute>
              <IncidentResponsePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pharmacist-dashboard"
          element={
            <PrivateRoute>
              <PharmacistDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/medication-dispensation"
          element={
            <PrivateRoute>
              <MedicationDispensationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/medication-history"
          element={
            <PrivateRoute>
              <MedicationHistoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/communication"
          element={
            <PrivateRoute>
              <SecureMessagingPage />
            </PrivateRoute>
          }
        />
        <Route path="/chat" element={<Message />} />
      </Routes>
    </Router>
  );
}

export default App;
