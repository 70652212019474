import React from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";

const DashboardPage = () => {
  const navigate = useNavigate();

  // Define your cards and corresponding navigation paths
  const cards = [
    {
      title: "UserManagementPage",
      image:
        "https://cdn.learnwoo.com/wp-content/uploads/2017/01/UserManagement.png",
      path: "/user-management",
    },

    {
      title: "Healthcare Provider Management",
      image:
        "https://gaine.com/wp-content/uploads/2022/08/iStock-1273886962.jpg",
      path: "/healthcare-provider-management",
    },

    {
      title: "System Configuration",
      image:
        "https://viso.ai/wp-content/uploads/2021/08/computer-vision-in-healthcare.jpg",
      path: "/system-configuration",
    },

    {
      title: "Data Oversight",
      image:
        "https://media.licdn.com/dms/image/D4E12AQGn9UvYqCKiDw/article-cover_image-shrink_720_1280/0/1680704863048?e=2147483647&v=beta&t=cV4Yd6BH9HJJt7y5q9GLY3zQ-eNsiV8elRBj6GFgvpA",
      path: "/data-oversight",
    },

    // {
    //   title: 'Report Generation',
    //   image: 'https://media.licdn.com/dms/image/D4E12AQGn9UvYqCKiDw/article-cover_image-shrink_720_1280/0/1680704863048?e=2147483647&v=beta&t=cV4Yd6BH9HJJt7y5q9GLY3zQ-eNsiV8elRBj6GFgvpA',
    //   path: '/report-generation'
    // },
  ];

  return (
    <div className="dashboard-page">
      <Navigation username="Admin" />
      <div className="dashboard-cards">
        {cards.map((card, index) => (
          <div
            key={index}
            className="dashboard-card"
            onClick={() => navigate(card.path)}
          >
            <div className="card-image-container">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-title">{card.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
