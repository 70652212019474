// PrescriptionManagementPage.js
import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation"; // Corrected import path
import "./PrescriptionManagementPage.css"; // CSS for styling this page
import axios from "axios";
import url from "../../url";

const PrescriptionManagementPage = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [prescriptionData, setPrescriptionData] = useState({
    medication: "",
    dosage: "",
    frequency: "",
  });

  const fetchPrescriptionData = async () => {
    try {
      const response = await axios.get(
        `${url}/prescriptions/patient/${sessionStorage.getItem("userId")}`
      );
      console.log(response);
      setPrescriptions(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPrescriptionData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPrescriptionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      prescriptionData.medication &&
      prescriptionData.dosage &&
      prescriptionData.frequency
    ) {
      try {
        const response = await axios.post(`${url}/prescriptions`, {
          ...prescriptionData,
          patient: sessionStorage.getItem("userId"),
        });
        console.log(response);
        setPrescriptionData({
          medication: "",
          dosage: "",
          frequency: "",
        });
        fetchPrescriptionData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/prescriptions/${id}`);
      console.log(response);
      fetchPrescriptionData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="prescription-management-container">
        <h1>Prescription Management</h1>
        <form onSubmit={handleSubmit} className="prescription-form">
          <input
            type="text"
            name="medication"
            placeholder="Medication Name"
            value={prescriptionData.medication}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="dosage"
            placeholder="Dosage (e.g., 500 mg)"
            value={prescriptionData.dosage}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="frequency"
            placeholder="Frequency (e.g., once daily)"
            value={prescriptionData.frequency}
            onChange={handleChange}
            required
          />
          <button type="submit" className="add-button">
            Add Prescription
          </button>
        </form>
        <ul>
          {prescriptions.map((prescription) => (
            <li key={prescription.id}>
              <p>
                {prescription.medication} - {prescription.dosage} -{" "}
                {prescription.frequency}
              </p>
              <button onClick={() => handleDelete(prescription.id)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PrescriptionManagementPage;
