import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/Navigation';

const DashboardPage = () => {
  const navigate = useNavigate();

  // Define your cards and corresponding navigation paths
  const cards = [
    {
        title: 'Medication Dispensation',
        image: 'https://nubo-cms-prod.oss-us-east-1.aliyuncs.com/prod/img/20220526114242564331.jpg',
        path: '/medication-dispensation'
      },  
      
      {
        title: 'Medication History',
        image: 'https://catchmeds.com/wp-content/uploads/2021/02/Hero-Image-2-01.png',
        path: '/medication-history'
      }, 

      {
        title: 'Communication',
        image: 'https://d34u8crftukxnk.cloudfront.net/slackpress/prod/sites/6/M5A2H%402x.jpg?d=500x500&f=inside',
        path: '/communication'
      }, 
      
    
  ];

  return (
    <div className="dashboard-page">
      <Navigation username="Admin" />
      <div className="dashboard-cards">
        {cards.map((card, index) => (
          <div
            key={index}
            className="dashboard-card"
            onClick={() => navigate(card.path)}
          >
            <div className="card-image-container">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-title">{card.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
