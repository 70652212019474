/* Samyukth challa 1002075109 */
// Array containing user objects with usernames, passwords, and roles.
export const Users = [
  { username: "user1", password: "password1", role: "patient", id: 1 },
  { username: "user2", password: "password2", role: "pharmacist", id: 2 },
  { username: "user3", password: "password3", role: "admin", id: 3 },
  { username: "user4", password: "password4", role: "healthAdmin", id: 4 },
  {
    username: "user5",
    password: "password5",
    role: "healthCareProvider",
    id: 5,
  },
  // Add more users as necessary
];

// Object containing role-based access routes.
export const roleAccess = {
  HealthAdmin: [
    "/health-administrator-dashboard",
    "/facility-management",
    "/staff-coordination",
    "/incident-response",
    "/compliance-oversight",
  ],
  patient: [
    "/patients-healthcare-dashboard",
    "/symptom-checker-page",
    "/medication-reminders-page",
    "/personal-health-records-page",
    "/prescription-management",
    "/patients-appointment-management",
    "/communication",
    // Include additional routes accessible by patients
  ],
  Pharmacist: [
    "/pharmacist-dashboard",
    "/medication-history",
    "/medication-dispensation",
    "/communication",
    // Include additional routes accessible by pharmacists
  ],
  admin: [
    "/administrator-dashboard",
    "/user-management",
    "/healthcare-provider-management",
    "/system-configuration",
    "/data-oversight",
    "/report-generation",
    // Include additional routes accessible by admins
  ],
  HealthcareProvider: [
    "/healthcare-providers-dashboard",
    "/e-prescriptions",
    "/appointment-management",
    "/health-records-access",
    "/professional-collaboration",
    "/communication",
    "/analytics-dashboard",
    // Include additional routes accessible by doctors
  ],
  // Add other roles as necessary
};

// Object containing initial routes for each user role.
export const entryRoute = {
  HealthAdmin: "/health-administrator-dashboard",
  patient: "/patients-healthcare-dashboard",
  Pharmacist: "/pharmacist-dashboard",
  admin: "/administrator-dashboard",
  HealthcareProvider: "/healthcare-providers-dashboard",
  // Define initial routes for other user roles
};
