import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import "./PatientsHealthcareDashboard.css";
import Navigation from "../../components/Navigation";

const DashboardPage = () => {
  const navigate = useNavigate();

  // Define your cards and corresponding navigation paths
  const cards = [
    {
      title: "Symptom Checker Page",
      image:
        "https://adigaskell.org/wp-content/uploads/2016/01/sympton-checker.jpg",
      path: "/symptom-checker-page",
    },

    {
      title: "Medication Reminders",
      image:
        "https://miro.medium.com/v2/resize:fit:1400/1*BBYDxgzLQi12aRNbZK4Wiw.jpeg",
      path: "/medication-reminders-page",
    },

    {
      title: "Personal Health Records Page",
      image:
        "https://assets-global.website-files.com/60ab7d80cd732355d467869f/6238989142bb417a9f60c08a_thumbnail.jpeg",
      path: "/personal-health-records-page",
    },

    {
      title: "Appointment Management",
      image:
        "https://schedule.cc/images/blog/appointment-scheduling-solution.png?v=1684144480862882721",
      path: "/patients-appointment-management",
    },

    {
      title: "Community Interactions",
      image:
        "https://images.ctfassets.net/pdf29us7flmy/4y2tFzoQTzmiTfeWqC70zf/b54e3d73eb7d1dbd7fedb4166d6af115/community-health-worker-US.png",
      path: "/community-interactions",
    },

    {
      title: "Prescription Management",
      image:
        "https://static1.squarespace.com/static/5a78c284be42d6493c55557d/t/60b7245212d078031a5b1aaa/1622615125720/IMG_2706.jpeg?format=1500w",
      path: "/prescription-management",
    },
  ];

  return (
    <div className="dashboard-page">
      <Navigation username="Admin" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "2rem 2rem 0 0",
        }}
      >
        <Link to={"/chat"}>
          <img
            style={{ width: "3rem", cursor: "pointer" }}
            src="/chat.png"
            alt="chatIcon"
          />
        </Link>
      </div>
      <div className="dashboard-cards">
        {cards.map((card, index) => (
          <div
            key={index}
            className="dashboard-card"
            onClick={() => navigate(card.path)}
          >
            <div className="card-image-container">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-title">{card.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
