import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navigation";
import "./UserManagementPage.css";
import axios from "axios";
import url from "../../url";

const UserManagementPage = () => {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      status: "Active",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane.smith@example.com",
      status: "Inactive",
    },
  ]);
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${url}/user-two`);
      console.log(response);
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const toggleUserStatus = async (
    userId,
    status,
    name,
    email,
    password,
    role
  ) => {
    try {
      const response = await axios.put(`${url}/user-two/update/${userId}`, {
        name: name,
        email: email,
        status: status === "Deactivate" ? "inactive" : "active",
        password: password,
        role: role,
      });
      console.log(response);
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="user-management-page">
      <Navigation />
      <div className="user-management-container">
        <h1>User Management</h1>
        <table className="user-list">
          <thead>
            <tr>
              <th>User ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>
                  {user.status}
                  {user.status === "active" ? (
                    <button
                      onClick={() =>
                        toggleUserStatus(
                          user.id,
                          user.status === "active" ? "Deactivate" : "Activate",
                          user.username,
                          user.email,
                          user.password,
                          user.role
                        )
                      }
                      className="admin-button deactivate"
                    >
                      Deactivate User
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        toggleUserStatus(
                          user.id,
                          user.status === "active" ? "Deactivate" : "Activate",
                          user.username,
                          user.email,
                          user.password,
                          user.role
                        )
                      }
                      className="admin-button activate"
                    >
                      Activate User
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="admin-actions"></div>
      </div>
    </div>
  );
};

export default UserManagementPage;
