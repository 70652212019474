// pages/LoginPage.js

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation";
import "./LoginPage.css";
import axios from "axios";
import { entryRoute } from "../Constants/Users";
import url from "../url";

const LoginPage = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  // Temporary hardcoded credentials for development purposes
  const tempUser = {
    email: "admin@example.com",
    password: "admin123",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    let validCreds = null;
    try {
      const response = await axios.post(`${url}/user-two/login`, {
        email: credentials.email,
        password: credentials.password,
      });
      console.log(response);
      if (response.data.data.status === "active") {
        validCreds = response.data.data;
      } else {
        alert("User Inactive");
      }
    } catch (error) {
      console.log(error);
      setError("Invalid email or password.");
    }
    if (validCreds) {
      sessionStorage.setItem("name", validCreds.name);
      sessionStorage.setItem("userRole", validCreds.role);
      sessionStorage.setItem("userId", validCreds.id);
      localStorage.setItem("user", validCreds);

      const initRoute = entryRoute[validCreds.role];
      initRoute && navigate(initRoute);
    } else {
      setError("Something went wrong");
    }

    // if (
    //   credentials.email === tempUser.email &&
    //   credentials.password === tempUser.password
    // ) {
    //   // Login is successful
    //   console.log("Login successful");

    //   // In a real app, you would want to replace this with a secure token received from your server.
    //   localStorage.setItem("token", "dummy-token");

    //   // Navigate to the dashboard page after successful login.
    //   navigate("/dashboard");
    // } else {
    //   // Login is not successful
    // }
  };

  return (
    <div className="login-page">
      <Navigation />
      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter email"
              value={credentials.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              value={credentials.password}
              onChange={handleInputChange}
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button">
            Log In
          </button>
        </form>
        Not Having a account? <Link to="/signup">Signup</Link>
      </div>
    </div>
  );
};

export default LoginPage;
