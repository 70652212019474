import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation"; // Corrected import path
import "./MedicationRemindersPage.css"; // Ensure this path is also correct
import axios from "axios";
import url from "../../url";

const MedicationRemindersPage = () => {
  const [medications, setMedications] = useState([
    {
      id: 1,
      name: "Aspirin",
      dosage: "100mg",
      frequency: "Daily",
      time: "08:00 AM",
    },
    {
      id: 2,
      name: "Metformin",
      dosage: "500mg",
      frequency: "Twice a day",
      time: "09:00 AM and 09:00 PM",
    },
    // Add more medications as needed
  ]);

  const fetchReminders = async () => {
    try {
      const response = await axios.get(
        `${url}/patients/medication-reminder/${sessionStorage.getItem(
          "userId"
        )}`
      );
      console.log(response);
      // setMedications(response.data?.medicationReminders);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrescriptionData = async () => {
    try {
      const response = await axios.get(
        `${url}/prescriptions/patient/${sessionStorage.getItem("userId")}`
      );
      console.log(response);
      setMedications(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPrescriptionData();
  }, []);

  useEffect(() => {
    fetchReminders();
  }, []);

  return (
    <div>
      <NavigationBar />
      <div className="medication-reminders-container">
        <h1>Medication Reminders</h1>
        <ul>
          {medications.map((med) => (
            <li key={med.id}>
              <h2>
                {med.medication} - {med.dosage}
              </h2>
              <p>
                Take <strong>{med.frequency}</strong>{" "}
                <strong>{med.time}</strong>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MedicationRemindersPage;
