// SecureMessagingPage.js
import React, { useState } from "react";
import NavigationBar from "../../components/Navigation";
import "./SecureMessagingPage.css"; // CSS for styling this page
import { useNavigate } from "react-router-dom";

const SecureMessagingPage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const navigate = useNavigate();

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (newMessage.trim()) {
      const timestamp = new Date().toISOString();
      setMessages([...messages, { text: newMessage, timestamp }]);
      setNewMessage(""); // Clear input after sending
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>Chat</h2>
      <p>For communications</p>
      <button className="admin-button" onClick={() => navigate("/chat")}>
        Community Chat
      </button>{" "}
      {/* Button to navigate to the Report Generation page */}
    </div>
  );
};

export default SecureMessagingPage;
