// pages/HomePage.js
import { Link, useNavigate} from "react-router-dom";
import React from "react";
import Navigation from "../components/Navigation";
import "./HomePage.css"; // Make sure this path is correct

// Import HeroSection if it exists and you want to use it
// import HeroSection from '../components/HeroSection';

const HomePage = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        // Perform logout operations like clearing local storage
        localStorage.removeItem('token');
        navigate('/login'); // Redirect to login after logout
      };
  return (
    <div>
      <Navigation />
      {/* Removed inline styles in favor of className */}
      <main className="main-content">
        <h2 className="vision-title">Vision of SmartHealth Hub</h2>
        <p className="vision-statement">
          The SmartHealth Hub's vision is to empower individuals and healthcare
          professionals through data security, interoperability, and a
          user-friendly experience.
        </p>
        {/* If you have a button or a link you want to style as a button, apply the buttonStyle here */}
        {localStorage.getItem("token") ? (
          <React.Fragment>
            <li>
              <span className="navbar-text">Welcome Admin</span>
            </li>
            <li>
              <button onClick={handleLogout} className="logout-button">
                Logout
              </button>
            </li>
          </React.Fragment>
        ) : (
          <li>
            <Link className="login-button" to="/login">Login</Link>
          </li>
        )}
      </main>
      {/* Footer would go here */}
    </div>
  );
};

export default HomePage;
