import React from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation";

const DashboardPage = () => {
  const navigate = useNavigate();

  // Define your cards and corresponding navigation paths
  const cards = [
    {
      title: "E-Prescriptions",
      image:
        "https://e1.nmcdn.io/dosespot/wp-content/uploads/2022/12/ePrescribing-Blog-Image-01.png/v:1-width:520-height:380-fit:cover/ePrescribing-Blog-Image-01.png?signature=4f6b3a01",
      path: "/e-prescriptions",
    },

    {
      title: "Appointment Management",
      image:
        "https://schedule.cc/images/blog/appointment-scheduling-solution.png?v=1684144480862882721",
      path: "/appointment-management",
    },

    {
      title: "Patient Health Records Access",
      image:
        "https://abyde.com/wp-content/uploads/2020/09/Patient-Right-of-Access-Blog-1.png",
      path: "/health-records-access",
    },

    {
      title: "Secure Messaging",
      image:
        "https://pixelprivacy.com/wp-content/uploads/2021/01/Encrypted-Messaging-Icon.png",
      path: "/communication",
    },

    {
      title: "Professional Collaboration",
      image:
        "https://online.une.edu/wp-content/uploads/2018/10/Interprofessional-Collaboration-1200x628-1.png",
      path: "/professional-collaboration",
    },

    {
      title: "Analytics Dashboard",
      image:
        "https://www.klipfolio.com/sites/default/files/integrations/Adobe-SiteCatalyst.png",
      path: "/analytics-dashboard",
    },
  ];

  return (
    <div className="dashboard-page">
      <Navigation username="Admin" />
      <div className="dashboard-cards">
        {cards.map((card, index) => (
          <div
            key={index}
            className="dashboard-card"
            onClick={() => navigate(card.path)}
          >
            <div className="card-image-container">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-title">{card.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
