// FacilityManagementPage.js
import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation";
import "./FacilityManagementPage.css"; // CSS for styling this page
import axios from "axios";
import url from "../../url";

const FacilityManagementPage = () => {
  const [facilities, setFacilities] = useState([
    {
      id: 1,
      name: "City Hospital",
      location: "123 Main St",
      services: "General Medicine, Pediatrics",
      status: "Active",
    },
    {
      id: 2,
      name: "Downtown Clinic",
      location: "456 Elm St",
      services: "Dermatology, Allergy",
      status: "Inactive",
    },
  ]);
  const [facility, setFacility] = useState({
    name: "",
    location: "",
    services: "",
    status: "Active",
  });
  const [isEdit, setIsEdit] = useState(false);

  const fetchFacilityData = async () => {
    try {
      const response = await axios.get(`${url}/facilities`);
      console.log(response);
      setFacilities(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchFacilityData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFacility((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isEdit) {
      try {
        const response = await axios.put(`${url}/facilities/${isEdit}`, {
          ...facilities,
        });
        console.log(response);
        setFacility({ name: "", location: "", services: "", status: "Active" });
        fetchFacilityData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await axios.post(`${url}/facilities`, {
          ...facilities,
        });
        console.log(response);
        setFacility({ name: "", location: "", services: "", status: "Active" });
        fetchFacilityData();
      } catch (error) {
        console.log(error);
      }
    }
    setIsEdit(false);
  };

  const handleEdit = (item) => {
    setIsEdit(item.id);
    setFacility(item);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${url}/facilities/${id}`);
      console.log(response);
      fetchFacilityData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="facility-management-container">
        <h1>Facility Management</h1>
        <form onSubmit={handleSubmit} className="facility-form">
          <input
            type="text"
            name="name"
            placeholder="Facility Name"
            value={facility.name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={facility.location}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="services"
            placeholder="Services Offered"
            value={facility.services}
            onChange={handleChange}
            required
          />
          <select name="status" value={facility.status} onChange={handleChange}>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          {isEdit ? (
            <button type="submit" className="add-button">
              Edit Facility
            </button>
          ) : (
            <button type="submit" className="add-button">
              Add Facility
            </button>
          )}
        </form>
        <ul>
          {facilities.map((facility) => (
            <li key={facility.id}>
              <p>
                {facility.name} - {facility.location} - {facility.services} -{" "}
                {facility.status}
              </p>
              <button onClick={() => handleDelete(facility.id)}>Remove</button>
              <button onClick={() => handleEdit(facility)}>Edit</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FacilityManagementPage;
