/*
Thirunagari
Sulakshana
1002190993
*/
import React, { useState } from "react";
import axios from "axios";
import Navigation from "../../components/Navigation";

const SymptomChecker = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const normalizedSearch = searchTerm;
      const options = {
        method: "POST",
        url: "https://chatgpt-42.p.rapidapi.com/conversationgpt4",
        headers: {
          "content-type": "application/json",
          "X-RapidAPI-Key":
            "ac175fca44mshac21c08954e9123p142105jsn3532b532d336",
          "X-RapidAPI-Host": "chatgpt-42.p.rapidapi.com",
        },
        data: {
          messages: [
            {
              role: "user",
              content: `Please give me best possible result for these sypmtoms ${normalizedSearch}`,
            },
          ],
          system_prompt: "",
          temperature: 0.9,
          top_k: 5,
          top_p: 0.9,
          max_tokens: 256,
          web_access: false,
        },
      };
      const response = await axios.request(options);
      console.log(response.data);
      return setSearchResult([
        { symptom: ` ${searchTerm}`, guidance: response?.data?.result },
      ]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Navigation />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2>Symptom Checker</h2>
        <p>
          Please describe any symptoms you are experiencing in the search bar
          below. Our system will help you find relevant information to assist
          you further.
        </p>
        <br />
        <div className="patient-symptomSearchContainer">
          <input
            type="text"
            class="patient-symptomSearchBar"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className="patient-symptomSearchButton"
            onClick={handleSearch}
            disabled={searchTerm === "" || loading}
          >
            Search
          </button>
        </div>
        <span className="helperTitles">Example Keywords: Fever, Cough ...</span>
        {searchResult?.length >= 1 && (
          <div className="patientCardContainer">
            <h2>Symptoms and Guidance</h2>
            <ul>
              {searchResult?.map((symptomObj, index) => (
                <li key={index}>
                  <strong>{symptomObj.symptom}:</strong>
                  <ul>
                    {symptomObj.guidance
                      .split(".")
                      .map(
                        (point, idx) =>
                          point.trim() && <li key={idx}>{point.trim()}.</li>
                      )}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        )}
        {loading && <p>Loading...</p>}
      </div>
    </>
  );
};

export default SymptomChecker;
