// MedicationDispensationPage.js
import React, { useEffect, useState } from "react";
import NavigationBar from "../../components/Navigation";
import "./MedicationDispensationPage.css"; // CSS for styling this page
import axios from "axios";
import url from "../../url";

const MedicationDispensationPage = () => {
  const [prescriptions, setPrescriptions] = useState([
    {
      id: 1,
      patientName: "John Doe",
      medication: "Amoxicillin",
      dosage: "500mg",
      quantity: "30",
      status: "Pending",
    },
    {
      id: 2,
      patientName: "Jane Smith",
      medication: "Ibuprofen",
      dosage: "200mg",
      quantity: "20",
      status: "Dispensed",
    },
  ]);

  const fetchPrescriptions = async () => {
    try {
      const response = await axios.get(`${url}/prescriptions`);
      console.log(response);
      setPrescriptions(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPrescriptions();
  }, []);

  const handleDispense = async (prescription) => {
    try {
      const response = await axios.post(`${url}/prescriptions`, {
        ...prescription,
        status: "Dispensed",
      });
      console.log(response);
      fetchPrescriptions();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <NavigationBar />
      <div className="medication-dispensation-container">
        <h1>Medication Dispensation</h1>
        <ul>
          {prescriptions.map((prescription) => (
            <li key={prescription.id}>
              <p>
                <strong>
                  {prescription.patient
                    ? prescription.patient
                    : "Not Available"}
                </strong>
                - Medicine :{" "}
                {prescription.medication
                  ? prescription.medication
                  : "paracetamol 650"}{" "}
                - Dosage : {prescription.dosage} x {prescription.frequency}
              </p>
              <p>Status: {prescription.status}</p>
              {prescription.status === "Pending" && (
                <button onClick={() => handleDispense(prescription)}>
                  Dispense
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MedicationDispensationPage;
