/*
  BOMMALI SNEHASHISH 
  1002127072
*/
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Paper, TextField, Grid } from "@mui/material";
import { FormControl } from "@mui/base";
import axios from "axios";
import url from "../url";
import Navigation from "../components/Navigation";

function RegistrationPage() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(false);
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleRegistration = async () => {
    console.log(formData);
    try {
      if (!otp) {
        const response = await axios.post(`${url}/user-two/request-otp`, {
          ...formData,
        });
        if (response.status === 200) {
          setOtp(true);
          alert(response.data.message);
          return;
        }
      }
      const response = await axios.post(`${url}/user-two/register`, {
        ...formData,
      });
      alert(response.data.message);
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background:
          "url('https://findado.osteopathic.org/wp-content/uploads/2021/08/GettyImages-1214206519.jpg')",
        backgroundSize: "cover",
        overflow: "none",
      }}
    >
      <Navigation />
      <div
        style={{
          minWidth: "80vw",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <Paper>
          <div style={{ width: "400px", margin: "3rem" }}>
            <h2>Signup</h2>
            <FormControl>
              <Grid display="flex" flexDirection={"column"} gap={3} container>
                <TextField
                  type="text"
                  id="name"
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  required
                />
                <TextField
                  type="email"
                  id="email"
                  onChange={handleChange}
                  placeholder="Email"
                  name="email"
                  required
                />
                <TextField
                  type="password"
                  id="password"
                  onChange={handleChange}
                  placeholder="Password"
                  name="password"
                  required
                />
                {otp && (
                  <TextField
                    type="text"
                    id="otp"
                    onChange={handleChange}
                    placeholder="OTP"
                    name="otp"
                    required
                  />
                )}
                <button onClick={handleRegistration}>Signup</button>
              </Grid>
            </FormControl>
            <p>
              Already have an account? <Link to="/login">Login </Link>
            </p>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default RegistrationPage;
