// MedicationHistoryPage.js
import React, { useState, useEffect } from "react";
import NavigationBar from "../../components/Navigation";
import "./MedicationHistoryPage.css"; // CSS for styling this page
import axios from "axios";
import { DatePicker, Input, InputNumber, Modal, Select, Form } from "antd";
import url from "../../url";

const MedicationHistoryPage = () => {
  const [patientMedications, setPatientMedications] = useState([
    {
      id: 1,
      medication: "Lisinopril",
      dosage: "10 mg",
      frequency: "once a day",
      startDate: "2021-01-10",
      endDate: "Present",
      condition: "Hypertension",
    },
    {
      id: 2,
      medication: "Metformin",
      dosage: "500 mg",
      frequency: "twice a day",
      startDate: "2020-12-01",
      endDate: "Present",
      condition: "Type 2 Diabetes",
    },
    {
      id: 3,
      medication: "Sertraline",
      dosage: "50 mg",
      frequency: "once a day",
      startDate: "2022-03-15",
      endDate: "Present",
      condition: "Depression",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [medicationHistory] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    medicationHistory.submit();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchMedicationHistory = async () => {
    try {
      const response = await axios.get(`${url}/medication-history`);
      console.log(response.data);
      setPatientMedications(response.data);
    } catch (error) {
      console.log(error);
    }
    medicationHistory.resetFields();
  };

  useEffect(() => {
    setTimeout(() => {
      fetchMedicationHistory();
      setLoading(false);
    }, 1000);
  }, []);

  const handleCreateMedicationHistory = async (values) => {
    try {
      const response = await axios.post(`${url}/medication-history`, {
        ...values,
      });
      console.log(response);
      fetchMedicationHistory();
    } catch (error) {
      console.log(error);
    }
    medicationHistory.resetFields();
  };

  return (
    <div>
      <NavigationBar />
      <div className="medication-history-container">
        <h1>Patient Medication History</h1>
        {loading ? (
          <p>Loading medication history...</p>
        ) : (
          <>
            <ul>
              {patientMedications.map((medication) => (
                <li key={medication.id}>
                  <p>
                    <strong>Medication:</strong> {medication.medication}
                  </p>
                  <p>
                    <strong>Dosage:</strong> {medication.dosage}
                  </p>
                  <p>
                    <strong>Frequency:</strong> {medication.frequency}
                  </p>
                  <p>
                    <strong>Period:</strong> {medication.duration}
                  </p>
                  <p>
                    <strong>Condition Treated:</strong> {medication.condtitions}
                  </p>
                </li>
              ))}
            </ul>
            <div
              style={{
                marginRight: "10px",
                cursor: "pointer",
                border: "1px solid blue",
                borderRadius: "2px",
              }}
              onClick={showModal}
            >
              Create New
            </div>
            <Modal
              title="New Medication"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form
                form={medicationHistory}
                onFinish={(values) => {
                  handleCreateMedicationHistory(values);
                }}
              >
                <Form.Item name="medication" label="Medication">
                  <Input placeholder="enter a medication name"></Input>
                </Form.Item>
                <Form.Item name="dosage" label="Dosage">
                  <InputNumber
                    style={{ width: "15rem" }}
                    placeholder="enter dosage"
                  ></InputNumber>
                </Form.Item>
                <Form.Item name="frequency" label="Frequency">
                  <Input
                    style={{ width: "15rem" }}
                    placeholder="enter frequency"
                  ></Input>
                </Form.Item>
                <Form.Item name="duration" label="Duration">
                  <Input placeholder="enter duration"></Input>
                </Form.Item>
                <Form.Item name="condtitions" label="Condition Treated">
                  <Input placeholder="enter the condition treated"></Input>
                </Form.Item>
              </Form>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default MedicationHistoryPage;
