/*Sangram Yathati- 1002168539*/
// HealthRecordsAccess.js
import React, { useEffect, useState } from "react";
import "./HealthRecordsAccess.css";
import axios from "axios";
import url from "../../url";
// Example data structure for patient records
const initialRecords = [
  {
    id: 1,
    name: "John Doe",
    age: 30,
    height: "6ft",
    medicalHistory: "No previous major illnesses.",
    address: "123 Elm Street",
    conditions: "Hypertension",
    medication: "Amlodipine",
  },
  {
    id: 2,
    name: "Jane Smith",
    age: 28,
    height: "5ft 6in",
    medicalHistory: "Allergic to penicillin.",
    address: "456 Oak Avenue",
    conditions: "Asthma",
    medication: "Albuterol",
  },
  {
    id: 3,
    name: "Alice Jones",
    age: 35,
    height: "5ft 4in",
    medicalHistory: "Diabetes Type II.",
    address: "789 Pine Road",
    conditions: "Diabetes",
    medication: "Metformin",
  },
  {
    id: 4,
    name: "Bob Brown",
    age: 40,
    height: "5ft 10in",
    medicalHistory: "Previous surgery on right knee.",
    address: "321 Maple Lane",
    conditions: "Arthritis",
    medication: "Ibuprofen",
  },
  {
    id: 5,
    name: "Carol White",
    age: 22,
    height: "5ft 5in",
    medicalHistory: "Recurrent tonsillitis.",
    address: "654 Spruce St",
    conditions: "Tonsillitis",
    medication: "Amoxicillin",
  },
];

const HealthRecord = ({ record, onUpdate }) => (
  <div className="record">
    <div>
      <strong>Name:</strong> {record.name}
    </div>
    <div>
      <strong>Age:</strong> {record.age}
    </div>
    <div>
      <strong>Height:</strong> {record.height}
    </div>
    <div>
      <strong>Medical History:</strong> {record.medicalHistory}
    </div>
    <div>
      <strong>Address:</strong> {record.address}
    </div>
    <div>
      <strong>Conditions:</strong> {record.conditions}
    </div>
    <div>
      <strong>medication:</strong> {record.medication}
    </div>
    <button onClick={() => onUpdate(record.id)}>Update</button>
  </div>
);

const PersonalHealthRecordsPage = () => {
  const [records, setRecords] = useState(initialRecords);
  const [editingId, setEditingId] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const [patients, setPatients] = useState([]);
  const [createFormData, setCreateFormData] = useState({
    patient: null,
    name: "",
    age: "",
    height: "",
    address: "",
    medicalHistory: "",
    conditions: "",
    medication: "",
  });
  const fetchHealthRecords = async () => {
    try {
      const response = await axios.get(`${url}/patients-health-record`);
      console.log(response);
      const filteredData = response.data.filter(
        (obj) => obj.patient === Number(sessionStorage.getItem("userId"))
      );
      console.log(filteredData);
      setRecords(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchHealthRecords();
  }, []);

  const handleUpdateClick = (id) => {
    const record = records.find((r) => r.id === id);
    setEditingId(id);
    setEditFormData(record);
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${url}/patients-health-record/${editingId}`,
        {
          ...editFormData,
          name: sessionStorage.getItem("name"),
          patient: sessionStorage.getItem("userId"),
        }
      );
      console.log(response);
      fetchHealthRecords();
    } catch (error) {
      console.log(error);
    }
    console.log(createFormData);
    setEditingId(null);
    setEditFormData(null);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleCreateFormChange = (event) => {
    const { name, value } = event.target;
    setCreateFormData({ ...createFormData, [name]: value });
  };

  const handleCreateRecord = async () => {
    try {
      const response = await axios.post(`${url}/patients-health-record`, {
        ...createFormData,
        name: sessionStorage.getItem("name"),
        patient: sessionStorage.getItem("userId"),
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    console.log(createFormData);
  };

  return (
    <div className="health-records">
      <h1>Personal Health Records</h1>
      {editingId ? (
        <form
          style={{ display: "flex", flexDirection: "column", maxWidth: "50%" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <input
            type="number"
            name="age"
            placeholder="Age"
            value={editFormData.age}
            onChange={handleFormChange}
            required
          />
          <input
            type="text"
            name="height"
            placeholder="Height"
            value={editFormData.height}
            onChange={handleFormChange}
            required
          />
          <textarea
            name="medicalHistory"
            placeholder="Medical History"
            value={editFormData.medicalHistory}
            onChange={handleFormChange}
            required
          ></textarea>
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={editFormData.address}
            onChange={handleFormChange}
            required
          />
          <input
            type="text"
            name="conditions"
            placeholder="Conditions"
            value={editFormData.conditions}
            onChange={handleFormChange}
            required
          />
          <input
            type="text"
            name="medication"
            placeholder="medication"
            value={editFormData.medication}
            onChange={handleFormChange}
            required
          />
          <button type="submit" onClick={() => handleSave(editingId)}>
            Save Changes
          </button>
        </form>
      ) : (
        <>
          <div style={{ marginBottom: "4rem" }}>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "50%",
                gap: "1rem",
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="number"
                name="age"
                placeholder="Age"
                value={createFormData.age}
                onChange={handleCreateFormChange}
                required
              />
              <input
                type="text"
                name="height"
                placeholder="Height"
                value={createFormData.height}
                onChange={handleCreateFormChange}
                required
              />
              <textarea
                name="medicalHistory"
                placeholder="Medical History"
                value={createFormData.medicalHistory}
                onChange={handleCreateFormChange}
                required
              ></textarea>
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={createFormData.address}
                onChange={handleCreateFormChange}
                required
              />
              <input
                type="text"
                name="conditions"
                placeholder="Conditions"
                value={createFormData.conditions}
                onChange={handleCreateFormChange}
                required
              />
              <input
                type="text"
                name="medication"
                placeholder="medication"
                value={createFormData.medication}
                onChange={handleCreateFormChange}
                required
              />
              <button type="submit" onClick={() => handleCreateRecord()}>
                Create
              </button>
            </form>
          </div>
          <div>
            {records.map((record) => (
              <HealthRecord
                key={record.id}
                record={record}
                onUpdate={handleUpdateClick}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalHealthRecordsPage;
