import React from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import "./DashboardPage.css";
import Navigation from "../components/Navigation";

const DashboardPage = () => {
  const navigate = useNavigate();

  // Define your cards and corresponding navigation paths
  const cards = [
    {
      title: "Patients/Healthcare Consumers",
      image:
        "https://www.shutterstock.com/image-vector/patient-icon-health-insurance-vector-600nw-1714375345.jpg",
      path: "/patients-healthcare-dashboard",
    },
    {
      title: "Healthcare Providers",
      image:
        "https://revcycleintelligence.com/images/site/features/VBCremediation_IMG.png",
      path: "/healthcare-providers-dashboard",
    },

    {
      title: "Administrator",
      image:
        "https://bachelors-completion.northeastern.edu/wp-content/uploads/2019/06/How-To-Become-A-Healthcare-Administrator-_-HeroImage.jpg",
      path: "/administrator-dashboard",
    },

    {
      title: "Health Administrator",
      image:
        "https://bachelors-completion.northeastern.edu/wp-content/uploads/2019/06/How-To-Become-A-Healthcare-Administrator-_-HeroImage.jpg",
      path: "/health-administrator-dashboard",
    },

    {
      title: "Pharmacist",
      image:
        "https://info.nhanow.com/hs-fs/hubfs/Med-bay.jpg?width=8207&name=Med-bay.jpg",
      path: "/pharmacist-dashboard",
    },
  ];

  return (
    <div className="dashboard-page">
      <Navigation username="Admin" />
      <div className="dashboard-cards">
        {cards.map((card, index) => (
          <div
            key={index}
            className="dashboard-card"
            onClick={() => navigate(card.path)}
          >
            <div className="card-image-container">
              <img src={card.image} alt={card.title} />
            </div>
            <div className="card-title">{card.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
